<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 my-4">
            <h1 class="text-second text-center">
                <i class="fa-solid fa-credit-card me-2"></i>
                {{ $t(`Travels.${this.$route.name}.RouteName`) }}
            </h1>
        </div>
        <div class="col-12 bg-second shadow-first rounded-5 p-4" v-if="!loader && payments">
            <DataTable :date="selectedDate" :tbody="payments" @filterByDate="filterByDate" @getReceipt="getReceipt"/>
        </div>
    </div>
    <transition name="fade-receipt">
        <Receipt :currentTimeStamp="currentTimeStamp" :receipt="receipt" @download="download" @close="receipt = null" v-if="receipt"/>
    </transition>
    <ReceiptLoader v-if="receiptLoader"/>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import DataTable from '../../components/DataTables/PaymentsDataTable.vue'
import Receipt from '../../components/Receipt.vue'
import ReceiptLoader from '@/components/ReceiptLoader.vue'
export default {
    name: 'PaymentsView',
    components: {
        DataTable,
        Receipt,
        ReceiptLoader
    },
    computed: {
        ...mapState(['loader', 'credentials', 'receiptLoader']),
        ...mapGetters(['ApiPath', 'ApiKey'])
    },
    data() {
        return {
            payments: null,
            selectedDate: null,
            currentTimeStamp: null,
            receipt: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setReceiptLoader']),
        getPayments(startDate, endDate) {
            this.setLoader(true)
            if (!startDate) {
                startDate = moment().subtract(29, 'days').set({hour:0,minute:0,second:0}).format()
            }
            if (!endDate) {
                endDate = moment().set({hour:23,minute:59,second:59}).format()
            }

            this.$http
                .post(`${this.ApiPath}/getPayments`,
                {
                    cardAccounts: this.credentials.cardAccounts,
                    startDate,
                    endDate
                },
                {
                    headers: {
                        'api-key': this.ApiKey
                    }
                })
                .then((res) => {
                    this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                    this.payments = res.data
                    this.setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async filterByDate(when) {
            this.payments = null
            this.payments = await this.getPayments(when.startDate, when.endDate)
        },
        getReceipt(payment) {
            this.setReceiptLoader(true)

            this.$http
                .post(`${this.ApiPath}/getReceipt`,
                {
                    payment
                },
                {
                    headers: {
                        'api-key': this.ApiKey
                    }
                })
                .then((res) => {
                    this.currentTimeStamp = this.$formatDate(moment(), localStorage.getItem('session_language'), Intl.DateTimeFormat().resolvedOptions().timeZone)
                    this.receipt = res.data
                    this.setReceiptLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        download() {
            html2pdf(document.getElementById('receipt'), {
                jsPDF: {
                    format: 'a5'
                },
                margin: 1,
                filename: `${this.$t(`Travels.${this.$route.name}.PaymentReceipt.receiptTitleStas`)}-${this.receipt.lastTapDate}.pdf`
            })
        }
    },
    created() {
        this.getPayments()
    }
}
</script>